import styled from 'styled-components';
import { FormGroup } from 'reactstrap';

export const StyledHtmlContainer = styled.div`
	appearance: none;
	width: 100%;
	height: 100%;
	max-height: 150px;
	overflow-y: auto;
	background: inherit;
	border: 1px solid #d9d9d9;
	outline: none;
	transition: 0.3s all;
	border-radius: 2px;
	cursor: text !important;
`;

export const StyledHtmlText = styled.div`
	padding: 1rem;
`;

export const StyledFormGroup = styled(FormGroup)`
	min-height: 150px;
	textarea {
		display: none;
	}
`;

export const StyledButton = styled.button`
	background: none;
	appearance: none;
	border: none;
	outline: none;
	padding: 0;
	width: fit-content;
	-moz-appearance: none;
	-webkit-appearance: none;

	&:hover,
	&:focus,
	&:focus-visible {
		outline: none;
		${StyledHtmlContainer} {
			border-color: #40a9ff;
			box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
		}
	}
`;

export const EditorContainer = styled(StyledFormGroup)`
	opacity: 0;
	transition: opacity 0.5s ease-in-out;

	&.initialized {
		opacity: 1;
	}
`;
