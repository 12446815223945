import React, { Suspense, lazy } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Loader from 'components/common/loaders/Loader.js';
// Import components
import { Col, Label } from 'reactstrap';
import TinyTextEditorPlaceholder from './TinyTextEditorPlaceholder';

//Import styles
import { EditorContainer, StyledFormGroup } from './styles';

// Import init
import { init_options } from 'components/common/inputs/texteditor/helpers';

// Import hook
import { useTinyTexEditorField } from './useTinyTexEditorField';

// Import helpers
import { REQUIRED_MARK } from 'components/helpers/variables';

// dynamic import
const Editor = lazy(() =>
	import('@tinymce/tinymce-react').then((module) => ({
		default: module.Editor
	}))
);
const TinyTextEditorField = ({
	input,
	id,
	label,
	labelColumn = 2,
	inputColumn = 10,
	children,
	fieldInfo = null,
	meta: { touched, error, submitError },
	disabledAddingImage,
	disabledAddingMedia,
	warningInfo = false,
	disabled,
	requiredField = false,
	...inputProps
}) => {
	const { t } = useTranslation();

	const {
		characterCount,
		handleEdit,
		handleEditorChange,
		handleInit,
		isEditing,
		isInitialized,
		renderFieldInfo
	} = useTinyTexEditorField({
		input,
		fieldInfo,
		meta: { touched, error, submitError }
	});

	return (
		<StyledFormGroup
			data-testid={`form-group-${input.name}`}
			row
			className={`${touched && error && 'has-error'}`}
		>
			<Label
				for={id}
				sm={labelColumn}
				className="col-form-label col-form-label-lg"
			>
				{label} {requiredField && REQUIRED_MARK}
			</Label>
			<Col sm={inputColumn}>
				{!isEditing ? (
					<TinyTextEditorPlaceholder handleEdit={handleEdit} input={input} />
				) : (
					<Suspense fallback={<Loader isLoaded={isInitialized} />}>
						<EditorContainer
							className={classNames({
								initialized: isInitialized
							})}
						>
							<Editor
								onInit={handleInit}
								apiKey={process.env.REACT_APP_TINYMCE_KEY}
								value={input.value}
								init={init_options({
									disabledAddingImage,
									disabledAddingMedia,
									disabled
								})}
								onEditorChange={handleEditorChange}
								disabled={disabled}
							/>
						</EditorContainer>
					</Suspense>
				)}

				{touched && (error || submitError) && (
					<span
						data-testid={`input-error-${input.name}`}
						className="input-error d-block"
					>
						{error || submitError}
					</span>
				)}
				<span className="d-block">
					{t('common:characters_count')}: {characterCount}
				</span>
				{warningInfo && (
					<span className="input-warning">
						{t('common:form.fields.validation.value_too_long')}
					</span>
				)}
				{renderFieldInfo()}
			</Col>
			{children}
		</StyledFormGroup>
	);
};

TinyTextEditorField.propTypes = {
	input: PropTypes.object.isRequired,
	label: PropTypes.string,
	id: PropTypes.string.isRequired,
	labelColumn: PropTypes.number,
	inputColumn: PropTypes.number,
	children: PropTypes.object,
	meta: PropTypes.object,
	fieldInfo: PropTypes.string,
	requiredField: PropTypes.bool
};

export default TinyTextEditorField;
